<template>
  <div>
    <br />
    <br />
    <form @submit.prevent="save(saleItem)">
      <div class="row align-items-end">
        <div class="col-auto">
          <label for=""> Client </label>
          <input
            type="text"
            :value="sale.customer ? sale.customer.fullName : ''"
            :disabled="true"
            class="form-control"
          />
        </div>

        <div class="col-3">
          <label for="">Catégorie</label>
          <select v-model="section_reference" class="form-select">
            <option
              v-for="(section, index) in sections"
              :key="index++"
              :value="section.reference"
            >
              {{ section.fullName }}
            </option>
          </select>
          <div class="invalid-feedback">More example invalid feedback text</div>
        </div>

        <div class="col-3">
          <label for="">Produit</label>
          <select
            v-model="saleItem.item_reference"
            required
            class="form-select"
          >
            <option
              v-for="(item, index) in section_reference != ''
                ? items.filter((p) => p.section_reference === section_reference)
                : items"
              :key="index++"
              :value="item.reference"
            >
              {{ item.fullName }}
            </option>
          </select>
          <div class="invalid-feedback">More example invalid feedback text</div>
        </div>

        <div class="col">
          <label for="">Quantité</label>
          <input v-model="saleItem.quantity" class="form-control" required />
        </div>
        <div class="col">
          <label for="">Prix</label>
          <input v-model="saleItem.priceTTC" class="form-control" />
        </div>
      </div>
      <br />
      <div class="row justify-content-end">
        <div v-if="showError" class="alert alert-danger">
          Please fill in all the required fields.
        </div>

        <div class="col-auto">
          <button class="btn btn-success" type="submit">
            <i class="bi bi-plus-circle"></i> Enregistrer
          </button>
        </div>

        <div class="col-auto">
          <button
            type="button"
            class="btn btn-secondary d-flex align-items-center"
            @click="refresh()"
          >
            <i class="bi bi-arrow-clockwise fs-4 me-2"></i>
            <span> Actualiser </span>
          </button>
        </div>
      </div>
    </form>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">Nº</th>
          <th scope="col">Désignation</th>
          <th scope="col">Prix (DH*)</th>
          <th scope="col">Quantité</th>
          <th scope="col">Montant Total (DH*)</th>
          <th scope="col">Stock</th>
          <th scope="col">Remarque</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in sale.sale_items" :key="index++">
          <td>
            {{ index }}
          </td>

          <td>
            <span v-if="item.item">
              {{ item.item.fullName }}
            </span>
          </td>
          <td class="">
            <span v-if="item.priceTTC != null">
              {{ item.priceTTC }}
            </span>
          </td>
          <td class="">
            <span v-if="item.quantity != null">
              {{ item.quantity }}
            </span>
            <span v-if="item.item">
              {{ item.item.measuring }}
            </span>
          </td>
          <td class="">
            <span v-if="item.totalPriceTTC != null">
              {{ item.totalPriceTTC }}
            </span>
          </td>

          <td>
            <span v-if="item.stock">
              {{ item.stock.fullName }}
            </span>
          </td>

          <td>
            {{ item.remark }}
          </td>

          <td class="d-flex">
            <button @click="remove(item)" class="btn text-danger">
              <i class="bi bi-trash-fill"></i>
            </button>
            <button
              @click="(saleItem = item), (section_reference = '')"
              class="btn text-secondary"
            >
              <i class="bi bi-pencil-square"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row justify-content-between">
      <div class="col text-center">
        <div class="w-auto">
          <span class="text-white bg-secondary rounded px-3 py-1">
            {{ sale.totalQuantity }}
          </span>
        </div>
      </div>

      <div class="col text-center">
        <div class="w-auto">
          <span class="text-white bg-primary rounded px-3 py-1">
            {{ sale.totalPriceTTC }}
          </span>
        </div>
      </div>

      <div class="col text-center">
        <div class="w-auto">
          <span class="text-white bg-success rounded px-3 py-1">
            {{ sale.amountPaid }}
          </span>
        </div>
      </div>

      <div class="col text-center">
        <div class="w-auto">
          <span class="text-white bg-danger rounded px-3 py-1">
            {{ sale.amountUnpaid }}
          </span>
        </div>
      </div>
    </div>
    <br />
    <div class="row mt-3 justify-content-between align-items-center">
      <div class="col-8">
        <div class="form-check align-items-center">
          <input
            type="checkbox"
            id="accept"
            value=""
            :checked="accept ? true : false"
            class="form-check-input me-2"
            @click="accept ? (accept = false) : (accept = true)"
          />
          <label class="form-check-label" for="accept">
            J'accepte que les informations relatives à l'achat ( Quantité totale
            : <strong>{{ totalQuantityItems }}</strong> Kg, Mantant totale :
            <strong>
              {{ totalPriceTTCItems }}
            </strong>
            DH, Nombre d'articles :

            <strong>{{ numberItems }}</strong> ) et correctes.
            <span class="text-danger">*</span>
          </label>
        </div>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-primary"
          @click="next(sale.reference)"
          :disabled="!accept ? true : false"
          v-if="accept"
        >
          <i class="bi bi-arrow-right-circle"></i> Suivant
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      saleItem: {},
      section_reference: "",
      accept: false,
      customer_change: true,
      showError: false,
      errorFields: [],
    };
  },
  computed: {
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("sale", {
      saleItems: "getItems",
      totalQuantityItems: "totalQuantityItems",
      totalPriceTTCItems: "totalPriceTTCItems",
      numberItems: "numberItems",
    }),

    ...mapGetters("sale", {
      sale: "getSale",
    }),
  },
  methods: {
    async save(item) {
      let quantity = parseFloat(item.quantity);
      let id = item.id;
      let sale_reference = item.sale_reference;
      let priceTTC = parseFloat(item.priceTTC);
      let item_reference = item.item_reference;
      let stock_reference = item.stock_reference;

      await this.$confirm({
        message: "Vous voulez modifier l'article  " + item.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store
              .dispatch("sale/updateItem", {
                quantity,
                id,
                sale_reference,
                priceTTC,
                item_reference,
                stock_reference,
              })
              .then(() => {
                // Call the refresh function after the remove action is completed
                this.refresh();
              });
            this.saleItem = {};
          }
        },
      });
    },
    async remove(data) {
      let item = data.item;
      await this.$confirm({
        message: "Vous voulez supprimer l'article  " + item.fullName,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("sale/destroyItem", data.id);
          }
        },
      });
    },
    async refresh() {
      this.$store.dispatch("sale/show", this.$route.params.reference);

      this.$store.dispatch("section/getAll");
      this.$store.dispatch("item/getAll");
      this.$store.dispatch("customer/getAll");
    },
    async next(reference) {
      let sale_reference = reference;
      this.$router.push({
        name: "sales-edit",
        query: { reference: sale_reference },
      });
    },
  },
  beforeMount() {
    this.$store.dispatch("sale/show", this.$route.params.reference);
    this.$store.dispatch("section/getAll");
    this.$store.dispatch("item/getAll");
    this.$store.dispatch("customer/getAll");
  },
};
</script>
